import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Typography, Box } from "@material-ui/core";

const StyledHeadline = styled(Typography)`
  font-size: 1.62671rem;
  font-weight: 300;
  line-height: 1.1;

  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.secondary.main};
  border-bottom-width: 1px;
`;

const StyledAnchor = styled.div`
  position: absolute;
  top: -100px;
`;

export const H2: FunctionComponent = (props) =>{
  const id: string = props.children?.toString()!;

  return (
    <Box mt={3} mb={1} position="relative">
      <StyledHeadline variant="h2">
        {props.children}
      </StyledHeadline>
      <StyledAnchor id={id} />
    </Box>
  );
}