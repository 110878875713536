import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Grid } from "@material-ui/core";
import { ContentsCard } from "../contentsCards/ContentsCard";

export const ExtensionsList: FunctionComponent = (props) =>{
  const data = useStaticQuery(
    graphql`
    {
      allFile(
        filter: {
          extension: {eq: "md"},
          sourceInstanceName: {eq: "contents"},
          childMarkdownRemark: {
            frontmatter: {
              publish: {eq: "true"},
              category: {eq: "extension"}
            }
          }
        },
        sort: {
          fields: childMarkdownRemark___frontmatter___date, order: DESC
        }
      )
      {
        edges {
          node {
            childMarkdownRemark {
              id
              excerpt(pruneLength: 160)
              frontmatter {
                title
                category
                date(formatString: "YYYY/MM/DD")
                cover{
                  childImageSharp{
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    }
    `
  );

  const markdowns = data.allFile.edges.map(edge => edge.node.childMarkdownRemark);

  const renderMarkdownRemark = (markdownRemark: any) => (
    <Grid item key={markdownRemark.id}>
      <ContentsCard
        title={markdownRemark.frontmatter.title}
        description={markdownRemark.excerpt}
        imageFluid={markdownRemark.frontmatter.cover?.childImageSharp.fluid}
        link={`/contents${markdownRemark.fields.slug}`}
        date={markdownRemark.frontmatter.date}
      />
    </Grid>
  );

  return(
    <Grid container direction="row" spacing={4}>
      {markdowns.map(md => renderMarkdownRemark(md))}
    </Grid>
  )
}