import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Layout } from "../templates/Layout";
import { Seo } from "../components/helmets/Seo";
import { Box } from "@material-ui/core";
import { BreadNav } from "../components/common/BreadNav";
import { H2 } from "../components/rehypes/H2";
import { ShareBelt } from "../components/social/ShareBelt";
import { FreeGameList } from "../components/contents/FreeGameList";
import { ExtensionsList } from "../components/contents/ExtensionsList";

export const ContentsPage = () =>{

  const data = useStaticQuery(
    graphql`
      query AllContents{
        allFile(
          filter: {
            extension: {eq: "md"}, 
            absolutePath: {regex: "/\\/contents\\//"}
          },
          sort: {
            fields: childMarkdownRemark___frontmatter___date,
            order: DESC
          }
        )
        {
          edges {
            node {
              childMarkdownRemark {
                id
                excerpt(pruneLength: 160)
                frontmatter {
                  title
                  cover {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  date(formatString: "YYYY/MM/DD")
                  category
                }
                fields {
                  slug
                }
              }
              absolutePath
            }
          }
        }
      }
    `
  );
  
  return (
    <Layout>
      <Seo title="Contents" />
      <Box my={2}>
        <BreadNav currentPage="Contents" links={[{label: "Home", link: "/"}]} />
      </Box>
      <H2>フリーゲーム</H2>
      <Box mt={2} mb={8}>
        <FreeGameList />
      </Box>
      <H2>ブラウザ拡張</H2>
      <Box mt={2} mb={8}>
        <ExtensionsList />
      </Box>
      {/* <H2>お手伝いしたもの</H2> */}
      <Box my={2}>
        <ShareBelt
          path="/contents/"
          title="Contents"
          description=""
        />
      </Box>
    </Layout>
  );
}


export default ContentsPage;